<template>
    <!--
    Компонент ввода значения
    -->

    <el-input class="dom-input"
              :class="{'has-left-icon': !!iconLeft}"
              v-model="valueInternal"
              :prefix-icon="iconLeft"
              :type="type"
              :readonly="readonly"
              :clearable="clearable"
              :placeholder="placeholder"
    />


</template>

<script>

import ValueInternal from '@/views/mixins/ValueInternal'

export default {
    name: "dom-input",

    mixins: [ValueInternal],

    props: {
        placeholder: {
            type: String
        },

        iconLeft: {
            type: String
        },

        type: {},

        readonly: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
    }
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-input {
    background-color: $dom-white;
    width: 100%;
    height: 50px;
    line-height: 50px;

    .icon-left {
        margin-top: 13px;
        margin-left: 16px;
    }

    ::v-deep .el-input__prefix {
        color: $dom-light-blue;
        font-size: 20px;
        padding-left: 11px;
    }

    &.has-left-icon {
        ::v-deep input {
            padding-left: 55px;
        }
    }

    ::v-deep input {
        height: 50px;
        border: none;
        outline: none;
        //padding-left: 55px;
        font-size: 18px;

        &[readonly] {
            color: $dom-dark-gray
        }
    }

}


</style>
