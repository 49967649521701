<!--
Миксин для добавление v-model к компоненту
-->

<script>
export default {
  props: {
    value: {
      required: true,
    }
  },
  computed: {
    valueInternal: {
      get() {return this.value},
      set(val) {this.$emit('input', val);}
    }
  }
}
</script>
