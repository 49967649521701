<template>
    <!--
    Компонент кнопки подтверждения
    -->

    <dom-button
        class="dom-button-accept"
        left-icon="el-icon-circle-check"
        right-icon="el-icon-caret-right"
        :label="label"
        :disabled="disabled"
        @click="$emit('click', $event)"
    >
        <slot/>
    </dom-button>

</template>

<script>
import DomButton from "@/views/elements/DomButton";

export default {
    components: {DomButton},
    props: {
        label: {},
        disabled: {}
    }
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-button-accept {
    height: 50px
}

</style>
