<template>
    <!--
    Компонент ввобда textarea
    -->
        <el-input
            class="dom-textarea"
            type="textarea"
            :rows="3"
            :maxlength="maxlength"
            show-word-limit
            :placeholder="placeholder"
            v-model="valueInternal">

        </el-input>
</template>

<script>

import ValueInternal from '@/views/mixins/ValueInternal'

export default {
    name: "dom-textarea",

    mixins: [ValueInternal],

    props: {
        placeholder: {
            type: String
        },
        iconLeft: {
            type: String
        },
        maxlength: {
            type: String,
            default: () => '256'
        }
    }
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-textarea {
    background-color: $dom-white;
    margin-top: 10px;

    .icon-left {
        color: $dom-light-blue;
        margin-top: 13px;
        margin-left: 5px;
    }
}

</style>
