<template>
    <!--
    Компонент стилизованная кнопка
    -->

    <el-button class="dom-button" @click="$emit('click', $event)" :disabled="disabled" :class="{disabled : disabled}">
        <i :class="leftIcon" style="font-size: 22px; font-weight: bold" v-if="leftIcon"/>
        <span style="font-size: 16px; margin-left: 20px" v-if="label">{{ label }}</span>
        <i :class="rightIcon" style="font-size: 16px; margin-left: 15px" v-if="rightIcon"/>
        <slot/>
    </el-button>

</template>

<script>
export default {
    name: "dom-button",
    props: {
        leftIcon: {},
        rightIcon: {},
        label: {},
        disabled: {}
    }
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-button {
    width: fit-content;
    background: $dom-button-gradient;

    ::v-deep * {
        color: $dom-white;
    }
}
.disabled {
    opacity: .3;
}
.el-button.is-disabled:hover {
    background: $dom-red;
}

</style>
