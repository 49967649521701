<!--
Миксин для компонента, который можно включать/выключать через v-model
-->

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
            required: false,
        }
    },
    computed: {
        isActive: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
    watch: {
        isActive(isActive) {
            if (isActive) this.__onToggleOn();
            else this.__onToggleOff();
        }
    },
    methods: {
        __onToggleOn() {},

        __onToggleOff() {},
    }
}
</script>

