<template>
    <!--
    Компонент заголовка с конопкой закрытия
    -->
    <div class="dom-title-closable">
        <div :span="20" style="text-align: start">
            <span style="font-weight: 600;">{{value}}</span>
        </div>
        <div :span="6" style="text-align: end">
            <el-link type="primary" :underline="false" style="font-size: 18px" @click="$emit('close')">
                <template v-if="$vssWidth > 850">{{ closeText }}</template>
                <i class="el-icon--right el-icon-error close-icon"></i>
            </el-link>
        </div>
    </div>
</template>
<script>
import VueScreenSize from "vue-screen-size";

export default {
    name: 'dom-title-closable',
    mixins: [VueScreenSize.VueScreenSizeMixin],
    props: {
        value: {},
        closeText: {
            default: "Закрыть"
        },
    }
}
</script>

<style scoped>

    .dom-title-closable {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

</style>
